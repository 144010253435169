
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { DataTableHeader } from 'vuetify/types'
import { OperationHistory } from '../../types/reservation'
import InfiniteLoading, { StateChanger } from 'vue-infinite-loading'
import UsedDyestuffs from '@/components/UsedDyestuffs.vue'

@Component({
  components: {
    InfiniteLoading,
    UsedDyestuffs,
  },
})
export default class ReservationOperationHistoryTable extends Vue {
  @Prop({ type: String, default: null }) readonly customer!: string | null
  @Prop({ type: String, default: '450px' }) readonly height!: string | number
  @Prop({ type: Boolean, default: false }) readonly hideStaffs!: boolean

  loading = false
  items: OperationHistory[] = []
  next: null | string = null

  get headers() {
    let rval: DataTableHeader[] = [
      {
        text: '施術日',
        value: 'date',
        width: 95,
      },
      {
        text: '根元',
        value: 'dyestuffRoot',
        class: ['text-no-wrap'],
        width: 300,
      },
      {
        text: '毛先',
        value: 'dyestuffEnd',
        class: ['text-no-wrap'],
        width: 150,
      },
      {
        text: '保護剤',
        value: 'scalpProtectiveAgent',
        width: 80,
      },
      {
        text: 'トリートメント',
        value: 'treatmentText',
        width: 120,
      },
      {
        text: '頭皮ケア',
        value: 'scalpCareName',
        width: 100,
      },
      ...(this.hideStaffs
        ? []
        : [
            {
              // NOTE: 以前は「施術対応」という名前
              text: 'カウンセリング',
              value: 'treatmentStaffName',
              class: ['text-no-wrap'],
            },
            {
              text: '塗布1',
              value: 'dyeingStaffName1',
              class: ['text-no-wrap'],
            },
            {
              text: '塗布2',
              value: 'dyeingStaffName2',
              class: ['text-no-wrap'],
            },
            {
              text: 'シャンプー',
              value: 'shampooStaffName',
              class: ['text-no-wrap'],
            },
          ]),
      {
        text: 'メニュー',
        value: 'menuName',
        width: 120,
      },
      {
        text: 'メモ',
        value: 'note',
        width: 300,
      },
      {
        text: '評価',
        value: 'rating.point',
      },
      {
        text: 'レビューコメント',
        value: 'rating.freeComment',
        width: 200,
      },
      {
        text: 'お店',
        value: 'shopName',
        width: 90,
      },
    ]
    rval.forEach(x => {
      x.sortable = false
    })
    return rval
  }

  @Watch('customer', { immediate: true })
  onCustomerChanged() {
    this.items = []
    this.next = null
  }

  async fetchData($state: StateChanger) {
    if (!this.customer) {
      $state.complete()
      return
    }
    try {
      this.loading = true
      let { results, next } = await (this.next
        ? this.$api.http.get(this.next)
        : this.$api.operationHistories().list({ customer: this.customer }))
      this.items = this.items.concat(results)
      this.$emit('items', this.items)
      this.next = next
      if (this.items.length) $state.loaded()
      if (!this.next) $state.complete()
    } catch (err) {
      console.error(err)
      $state.error()
    }
    this.loading = false
  }

  rowColor(item: OperationHistory) {
    if (!item.isActive) {
      return 'grey'
    }
  }
}
